.Index\/StartDate {
  width: 550px;
  margin: 0 auto;

  &__title {
    font-family: 'Rasa', sans-serif;
    font-size: 44px;
    font-weight: 500;
    margin: 0 0 10px 0;
  }

  &__subtitle {
    width: 380px;
    line-height: 24px;
  }

  &__next-step {
    background: white;
    width: 230px;
    box-sizing: border-box;
    padding: 32px;
    position: fixed;
    bottom: 50px;
    right: 50px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }

  &__next-step-message {
    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
  }

  &__start-date-label {
    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
  }

  &__start-date {
    font-size: 18px;
  }

  &__link {
    background: #92bfff;
    color: inherit;
    padding: 10px 20px 10px 32px;
    border-radius: 4px;
    text-decoration: none;
    display: block;
    position: relative;

    &::before {
      content: '';
      display: inline-block;
      border: 6px solid transparent;
      border-left-color: currentColor;
      position: absolute;
      top: 50%;
      left: 16px;
      transform: translateY(-50%);
    }
  }
}
