.Index {
  &__title {
    div:nth-child(1) {
      font-weight: normal;
      font-size: 20px;
      line-height: 22px;
      margin-bottom: 8px;
    }

    div:nth-child(2) {
      font-family: 'Rasa', sans-serif;
      font-size: 48px;
      font-weight: 500;
      line-height: 48px;
    }

    div:nth-child(3) {
      font-weight: 300;
      color: rgba(0, 0, 0, 0.5);
      font-size: 20px;
      line-height: 20px;
      letter-spacing: 2.8px;
    }
  }

  &__header {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  &__picture {
    flex: 0 0 auto;
    width: 400px;
    margin-right: 100px;
    position: relative;
    box-shadow: -20px 20px 0 0 #e4d1ac;
  }

  &__heading {
    flex: 0 0 auto;
    width: 320px;

    p {
      margin: 0 0 20px 0;
      line-height: 28px;
    }

    u {
      text-decoration: none;
      position: relative;

      &::after {
        content: '';
        width: 100%;
        height: 2px;
        bottom: -4px;
        left: 0;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.3);
      }
    }

    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
    }

    li {
      margin-bottom: 16px;
    }
  }

  &__value {
    background-color: var(--highlight-color);
    padding: 4px 8px;
  }

  &__footer {
    width: 550px;
    margin: 0 auto;
  }
}
