.Trips\/StartDateFilter {
  border-radius: 4px;
  background: white;
  display: flex;
  align-items: stretch;
  flex: 1 1 0;
  min-width: 0;
  border: 1px solid rgba(0, 0, 0, 0.2);
  flex: 1 1 0;
  display: flex;
  min-width: 0;
  border-radius: 4px;

  &--open {
    border-color: #2363be;
    box-shadow: 0 0 0 1px #2363be;
  }

  &__dropdown {
    padding: 16px;
    box-sizing: border-box;
    background: white;
    border-radius: 4px;
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    z-index: 1;

    &:focus {
      outline: none;
    }
  }

  &__start-date-button-wrapper {
    flex: 1 1 0;
    min-width: 0;
  }

  &__start-date-button {
    background: none;
    border: none;
    padding: 12px 20px;
    width: 100%;

    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  &__previous-date-button,
  &__next-date-button,
  &__previous-month-button,
  &__next-month-button {
    background: none;
    border: none;
    position: relative;
    width: 8px;
    box-sizing: content-box;

    &:not(:disabled) {
      cursor: pointer;
    }

    &:disabled {
      opacity: 0.5;
    }

    &:focus {
      outline: none;
    }

    // Arrow
    &:before {
      content: '';
      position: absolute;
      border: solid rgba(0, 0, 0, 0.4);
      border-width: 2px 2px 0 0;
      width: 6px;
      height: 6px;
    }
  }

  &__previous-date-button,
  &__next-date-button {
    padding: 12px 6px;
  }

  &__previous-month-button,
  &__next-month-button {
    padding: 0 6px;
    height: 20px;
  }

  &__previous-date-button,
  &__previous-month-button {
    &:before {
      transform: translate(2px, -4px) rotate(-135deg);
    }
  }

  &__next-date-button,
  &__next-month-button {
    &:before {
      transform: translate(-2px, -4px) rotate(45deg);
    }
  }

  &__previous-date-button {
    padding-left: 12px;
  }

  &__next-date-button {
    padding-right: 12px;
  }

  &__month-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }
}
