.Trips\/Menu {
  width: 300px;
  box-sizing: border-box;
  background: white;
  border-radius: 4px;
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  z-index: 1;

  &:focus {
    outline: none;
  }

  &__item {
    padding: 16px 16px 16px 32px;
    cursor: pointer;
    user-select: none;
    position: relative;

    &:not(:first-child) {
      border-top: 1px solid rgba(0, 0, 0, 0.2);
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.03);
    }

    &--selected {
      &::after {
        content: '';
        position: absolute;
        z-index: 1;
        background-color: #1066e1;
        top: -1px;
        left: 0;
        width: 3px;
        height: calc(100% + 2px);
      }
    }
  }

  &__item-label {
    font-weight: 500;
    color: black;
  }

  &__item-pro,
  &__item-con {
    font-size: 14px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.6);
    margin-top: 8px;
    position: relative;

    &::before {
      content: '';
      width: 4px;
      height: 4px;
      position: absolute;
      top: 7px;
      right: calc(100% + 10px);
    }

    strong {
      font-weight: normal;
    }
  }

  &__item-pro {
    &::before {
      background-color: #517300;
    }
    strong {
      color: #517300;
    }
  }

  &__item-con {
    &::before {
      background-color: var(--danger-color);
    }
    strong {
      color: var(--danger-color);
    }
  }
}
