.Trip\/Summary {
  display: flex;
  justify-content: center;
  position: relative;

  &__title {
    font-weight: normal;
    font-size: 28px;
    line-height: 32px;
    margin: 0 0 20px 0;
  }

  &__left-column,
  &__right-column {
    width: 460px;
    flex: 0 0 auto;
    min-width: 0;
  }

  &__left-column {
    z-index: 1;
    width: 360px;
    margin-right: 20px;
  }

  &__right-column {
    min-height: 700px;
  }

  &__card-wrapper {
    margin-top: 40px;
  }

  &__map-attribution {
    position: absolute;
    bottom: -20px;
    right: 0px;
  }
}
