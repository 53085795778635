.Chart\/Stop {
  &__backdrop {
    fill: white;
    stroke-width: 6px;
    stroke: var(--background-color);
  }

  &__inner {
    fill: #72675e;
    stroke-width: 1.5px;
    stroke: #373635;
  }

  &__icon {
    fill: var(--background-color);
  }

  &__label {
    position: relative;
    overflow: visible;
  }

  &__label-inner {
    // Using fixed instead of absolute fixes a bug in Safari
    position: fixed;
    bottom: 16px;
    width: 65px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: rgba(0, 0, 0, 0.9);
    text-shadow: 0px 1px var(--background-color),
      0px -1px var(--background-color), 1px 0px var(--background-color),
      -1px 0px var(--background-color), 1px 1px var(--background-color),
      -1px -1px var(--background-color);

    &--left {
    }

    &--center {
      text-align: center;
      transform: translateX(-50%);
    }

    &--right {
      text-align: right;
      transform: translateX(-100%);
    }
  }
}
