.StaticMap\/Attribution {
  color: rgba(0, 0, 0, 0.3);
  line-height: 16px;
  display: inline-flex;

  &__logo {
    opacity: 0.2;
    height: 16px;
  }

  &__link {
    color: inherit;
    text-decoration: none;
    font-size: 12px;
    margin-left: 12px;
  }
}
