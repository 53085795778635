.Trips\/TripList {
  &__header {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    margin: 32px 0 16px 0;
    color: rgba(0, 0, 0, 0.5);
  }
}
