.Footer {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  padding: 20px 0;
  color: rgba(0, 0, 0, 0.5);

  &__link {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
