.StaticMap {
  position: relative;

  &__bleed {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__basemap,
  &__elements {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__fog {
    fill: #f9f4ec;
  }

  &__arrow-marker {
    fill: #1066e1;
  }

  &__attribution {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
