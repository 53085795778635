.Trip\/Permit {
  // To leave a 20px gap above the title when the browser scrolls to this anchor
  padding-top: 20px;

  &__title {
    font-size: inherit;
    font-weight: normal;
    text-align: center;
    position: relative;
    color: rgba(0, 0, 0, 0.5);

    &::before {
      content: '';
      height: 1px;
      width: 100%;
      left: 0;
      top: 50%;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.2);
      z-index: 0;
    }
  }

  &__title-inner {
    background: #f9f4ec;
    z-index: 1;
    position: relative;
    padding: 0 16px;
  }

  &__inner {
    display: flex;
    justify-content: center;
  }

  &__column {
    flex: 0 0 auto;
    width: 300px;

    &:not(:last-child) {
      margin-right: var(--gutter-width);
    }
  }

  &__option-title {
    font-weight: normal;
    font-size: 28px;
    line-height: 32px;
    text-align: center;
  }

  &__highlight {
    background-color: var(--highlight-color);
    padding: 0 4px;
  }

  &__price {
    text-align: center;
    font-size: 20px;
    line-height: 24px;
  }

  &__notice {
    text-align: center;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.5);
    margin-top: 8px;
  }

  &__card {
    background-color: white;
    border-radius: 4px;
    padding: 32px;
    margin-top: 40px;
    text-decoration: none;
    box-sizing: border-box;
    box-shadow: 4px 4px 0 0px rgba(0, 0, 0, 0.1);
    color: inherit;
    display: block;
    border: 3px solid transparent;
    transition: border-color 0.2s;

    &:hover {
      border-color: rgba(0, 0, 0, 0.1);
    }

    &--highlighted {
      border-color: #ffbe3c;

      &:hover {
        border-color: #d38d00;
      }
    }
  }

  p {
    margin: 0 0 16px 0;
  }

  strong {
    font-weight: 500;
  }

  img {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    margin-top: 16px;
  }

  &__apply-button {
    border-radius: 4px;
    border: 0;
    background-color: #92bfff;
    padding: 12px 24px;
    margin: 16px 0 0 0;
    cursor: pointer;
    width: 100%;
    text-align: center;
  }

  &__download-button {
    border: 0;
    margin: 16px 0 0 0;
    cursor: pointer;
    width: 100%;
    padding: 0;
    background: none;
    color: #1066e1;

    &:hover {
      text-decoration: underline;
    }
  }
}
