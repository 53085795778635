.Trips\/HelpTooltip {
  &__tooltip {
    background: #333;
    color: rgba(255, 255, 255, 0.95);
    padding: 6px 8px;
    max-width: 250px;
    font-size: 14px;
    line-height: 20px;
    border-radius: 4px;
  }

  &__icon {
    height: 14px;
    opacity: 0.3;
    vertical-align: top;
    position: relative;
    top: 1px;
    cursor: help;
  }
}
