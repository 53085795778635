.Index\/Overview {
  width: 860px;
  margin: 0 auto;
  position: relative;
  display: flex;
  justify-content: space-between;

  &__column {
    width: 300px;
  }

  &__title {
    font-family: 'Rasa', sans-serif;
    font-size: 44px;
    font-weight: 500;
  }

  &__text {
    line-height: 24px;
  }

  &__table {
    width: 100%;
    border-collapse: collapse;

    tr:first-child td {
      border-top: 0;
    }

    td {
      padding: 8px 0;
      border-top: 1px solid rgba(0, 0, 0, 0.2);

      &:last-child {
        text-align: right;
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }

  &__image {
    height: 800px;
    transform: translateX(100px);
  }

  &__map-attribution {
    position: absolute;
    bottom: -40px;
    right: -100px;
  }
}
