.StaticMap\/ShuttleSegment {
  &__curve {
    fill: none;
    stroke: #1066e1;
    stroke-width: 3px;
    stroke-dasharray: 8 4;
  }

  &__label-back {
    fill: #1066e1;
  }

  &__label {
    fill: white;
    font-size: 14px;
    text-anchor: middle;
    dominant-baseline: middle;
  }
}
