.Layout\/Row {
  display: flex;
  flex-direction: row;
}

.Layout\/Tall {
  flex: 0 0 auto;

  &--fluid {
    flex: 1 1 0;
    min-height: 0;
  }
}

.Layout\/Wide {
  display: inline-block;
  flex: 0 0 auto;

  &--fluid {
    flex: 1 1 0;
    min-width: 0;
  }
}
