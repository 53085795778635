.Index\/Tooltip {
  cursor: help;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -2px;
    border-bottom: 1px dotted rgba(0, 0, 0, 0.2);
  }

  &__tooltip {
    background: #333;
    color: rgba(255, 255, 255, 0.95);
    padding: 6px 8px;
    max-width: 250px;
    font-size: 14px;
    line-height: 20px;
    border-radius: 4px;
  }
}
