.Trip\/Tasks {
  &__message {
    color: rgba(0, 0, 0, 0.5);
  }

  &__task {
    // Resets link style
    color: inherit;
    display: block;
    text-decoration: inherit;

    background-color: white;
    padding: 20px 16px 20px 72px;
    border-radius: 4px;
    box-shadow: 4px 4px 0 0px rgba(0, 0, 0, 0.1);
    position: relative;
    width: 360px;
  }

  &__task-index {
    left: 28px;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    font-size: 28px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.3);
  }

  &__task-primary,
  &__task-secondary {
    width: var(--column-width);
    flex: 0 0 auto;
  }

  &__task-primary-add-on {
    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
    display: inline-block;

    &--highlighted {
      background-color: var(--highlight-color);
      color: rgba(0, 0, 0, 0.7);
      padding: 0 4px;
    }
  }

  &__task-primary-inner {
    font-weight: 500;
  }
}
