.Trip {
  --column-width: 300px;
  --gutter-width: 30px;

  position: relative;
  scroll-behavior: smooth;

  &__title {
    font-family: 'Rasa', sans-serif;
    font-size: 44px;
    line-height: 42px;
    font-weight: 500;
  }

  &__date-range {
    background-image: url('~assets/calendar-range.svg');
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: left center;
    padding-left: 32px;
  }

  &__back-link {
    position: fixed;
    top: 20px;
    left: 20px;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.5);
    line-height: 24px;
    transition: color 0.2s;

    &:hover {
      color: rgba(0, 0, 0, 0.9);
      &::before {
        opacity: 0.7;
      }
    }

    &::before {
      content: '';
      background-image: url('~assets/arrow-left.svg');
      background-size: 100%;
      display: inline-block;
      width: 24px;
      height: 24px;
      opacity: 0.5;
      margin-right: 8px;
      vertical-align: middle;
      position: relative;
      top: -2px;
      transition: opacity 0.2s;
    }
  }

  &__content {
    width: calc(var(--column-width) * 2 + var(--gutter-width));
    margin: 0 auto;
  }

  &__section-title {
    font-weight: normal;
    font-size: 28px;
    line-height: 36px;
    margin: 120px 0 0 0;
  }

  &__external-link {
    margin-top: 16px;
    display: inline-block;
    color: rgba(0, 0, 0, 0.5);
    position: relative;
    text-decoration: none;

    &::after {
      content: '';
      height: 2px;
      width: 100%;
      position: absolute;
      background: rgba(0, 0, 0, 0.2);
      bottom: -2px;
      left: 0;
    }
  }

  &__divider {
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    margin: 0;
  }
}
