.StaticMap\/Stop {
  // Used by the icon (uses currentColor)
  color: #f9f4ec;

  &__back {
    fill: #373635;
    stroke: #f9f4ec;
    stroke-width: 3px;
  }

  &__front {
    fill: #72675e;
  }

  &__label {
    overflow: visible;
  }

  &__label-inner {
    font-weight: 500;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.9);
    text-shadow: 0px 1px #f9f4ec, 0px -1px #f9f4ec, 1px 0px #f9f4ec,
      -1px 0px #f9f4ec, 1px 1px #f9f4ec, -1px -1px #f9f4ec;
  }
}
