.Timeline\/Group {
  &:not(:last-child) {
    margin-bottom: 32px;
  }

  &__title {
    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 16px;
  }
}
