.TripCard {
  background-color: white;
  padding: 32px 0;
  box-shadow: 4px 4px 0 0px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  max-height: 630px;
  width: 360px;

  &--stretch {
    max-height: 100%;
  }

  &--highlighted {
    border: 4px solid var(--highlight-color);
  }

  &__header {
    flex: 0 0 auto;
    display: flex;
    align-items: flex-start;
    margin-bottom: 32px;
    padding: 0 32px;
  }

  &__title {
    flex: 1 1 0;
    min-width: 0;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    min-height: calc(28px * 2); // 2 lines high
  }

  &__pick-button {
    border-radius: 4px;
    border: 0;
    background-color: #92bfff;
    padding: 12px 24px;
    margin-left: 32px;
    cursor: pointer;
  }

  &__expand-button {
    appearance: none;
    border: none;
    background: none;
    padding: 0;
    color: #1066e1;
    cursor: pointer;
    margin: 0 32px;
    align-self: flex-start;

    // TODO add focus state
    &:focus {
      outline: none;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  &__timeline {
    overflow: auto;
    flex: 1 1 0;
  }

  &__timeline-wrapper {
    flex: 1 1 0;
    position: relative;
    min-height: 0;
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      height: 40px;
      width: 100%;
      z-index: 2;
      pointer-events: none;
    }

    &::before {
      top: 0;
      // In a linear gradient, for Safari, "transparent" is black with 0
      // opacity, so using rgba(255, 255, 255, 0) to force using white with 0
      // opacity.
      background: linear-gradient(to top, rgba(255, 255, 255, 0), white);
    }

    &::after {
      bottom: 0;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white);
    }

    &--at-top::before {
      display: none;
    }

    &--at-bottom::after {
      display: none;
    }
  }

  &__timeline-inner {
    padding: 0 32px;
  }

  &__date-range {
    background-image: url('~assets/calendar-range.svg');
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: left center;
    padding-left: 32px;
    color: rgba(0, 0, 0, 0.5);
  }
}
