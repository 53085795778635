.Trips\/ParkingFilter {
  &__item-description {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    margin-top: 16px;
    line-height: 18px;
  }

  &__item-pro {
    color: #517300;
  }

  &__item-con {
    color: var(--danger-color);
  }

  &__estimates {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    margin-top: 8px;
  }

  &__estimate {
    margin-top: 8px;
    width: calc((100% - 20px) / 2);
    display: flex;
  }

  &__destination {
    flex: 1 1 0;
  }

  &__duration {
    color: rgba(0, 0, 0, 0.4);
  }
}
