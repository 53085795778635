.Trips\/Filter {
  display: inline-block;
  margin: 0 8px 8px 0;

  &__trigger {
    border-radius: 100px;
    background: #e8ddd4;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    border: 0;
    padding: 0;
    transition: background-color 0.2s;

    &:focus {
      outline: 0;
    }

    &:hover {
      background-color: #efe2d8;
    }

    &--open,
    &--active {
      &,
      &:hover {
        background-color: white;
      }
    }
  }

  &__label {
    padding: 8px 16px;
    line-height: 18px;
  }

  &__caret {
    border: 5px solid;
    border-color: rgba(0, 0, 0, 0.4) transparent transparent transparent;
    margin: 0 12px 0 -8px;
    position: relative;
    top: 3px;
  }
}
