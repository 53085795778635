.StaticMap\/Segment {
  &__back,
  &__front {
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  &__back {
    stroke: #b95728;
    stroke-width: 7px;
  }

  &__front {
    stroke: #e9ac8f;
    stroke-width: 4px;
  }
}
