.Index\/ApplicationPeriod {
  &__title {
    font-size: 24px;
    font-weight: normal;
  }

  &__info-table {
    width: 100%;
    border-collapse: collapse;
  }

  &__info td {
    color: rgba(0, 0, 0, 0.5);
    padding: 4px 0;
  }

  &__notes {
    color: rgba(0, 0, 0, 0.5);
    font-style: italic;
  }
}
