@import url('https://fonts.googleapis.com/css2?family=Rasa:wght@500&family=Roboto:wght@300;400;500&display=swap');

:root {
  --base-text-color: rgba(0, 0, 0, 0.9);
  --danger-color: #b94a4a;
  --highlight-color: #fdcf74;

  --scrollbar-width: 4px;
}

// Default to border-box
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: var(--base-text-color);
  background: #f9f4ec;

  ::-webkit-scrollbar {
    width: var(--scrollbar-width);
    height: var(--scrollbar-width);
  }

  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 1px;
  }
}

button,
textarea,
select,
input {
  font-family: inherit;
  color: inherit;
  line-height: inherit;
  font-size: inherit;
  text-align: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  line-height: 22px;
}
