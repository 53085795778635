.Trips {
  --results-width: 430px;

  display: flex;
  width: 100%;
  height: 100%;
  position: fixed;
  box-sizing: border-box;

  &__results {
    flex: 0 0 var(--results-width);
    overflow: auto;
    padding: 16px 32px 16px 32px;
  }

  &__selection {
    display: flex;
    flex: 1 1 0;
    align-items: stretch;
    min-width: 0;
    position: relative;
    margin-left: 16px;
  }

  &__trip-card-container {
    width: 360px;
    flex: 0 0 auto;
    padding: 16px 0;
    z-index: 1;
  }

  &__map-container {
    flex: 1 1 0;
    min-width: 0;
    display: flex;
  }

  &__map-attribution {
    position: absolute;
    right: 8px;
    bottom: 0px;
  }

  &__back-button {
    display: inline-block;
    top: 24px;
    left: 24px;
    vertical-align: middle;
    margin-right: 8px;

    &::before {
      content: '';
      display: block;
      height: 36px;
      width: 36px;
      background-image: url('~assets/arrow-left.svg');
      background-repeat: no-repeat;
      background-position: center;
      opacity: 0.5;
    }

    &:hover {
      &::before {
        opacity: 1;
      }
    }
  }

  &__top-filters {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  &__filters-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    margin: 16px 0;
    color: rgba(0, 0, 0, 0.5);
  }

  &__no-matches-notice {
    color: rgba(0, 0, 0, 0.5);
    margin-top: 40px;
    line-height: 24px;
  }

  &__clear-button {
    appearance: none;
    border: 0;
    display: inline;
    background: none;
    padding: 0;
    color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    position: relative;

    // TODO add focus state
    &:focus {
      outline: none;
    }

    &:hover {
      &::before {
        background-color: rgba(0, 0, 0, 0.5);
      }
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 1px;
      left: 0;
      right: 0;
      height: 1px;
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
}
