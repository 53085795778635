.Chart\/HikingSegment {
  &__segment {
    fill: #e3dcd7;
  }

  &__line-back {
    stroke: #b95728;
    stroke-width: 5px;
  }

  &__line {
    stroke: #e9ac8f;
    stroke-width: 2.5px;
  }

  &__distance,
  &__elevation-change {
    fill: rgba(0, 0, 0, 0.5);
    text-anchor: middle;
    font-size: 12px;
    line-height: 12px;
    font-weight: 500;
  }

  &__ascent {
    fill: var(--danger-color);
  }

  &__descent {
    fill: rgba(0, 0, 0, 0.5);
  }
}
