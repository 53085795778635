.TripListItem {
  padding: 16px;
  display: flex;
  position: relative;
  margin: 0 -16px;
  border-radius: 4px;
  align-items: baseline;
  transition: background-color 0.2s;
  user-select: none;
  font-size: 14px;
  line-height: 16px;

  &::before {
    content: '';
    position: absolute;
    height: 1px;
    top: 0;
    left: 16px;
    right: 16px;
    background: rgba(0, 0, 0, 0.2);
  }

  &:last-child::after {
    content: '';
    position: absolute;
    height: 1px;
    bottom: -1px;
    left: 16px;
    right: 16px;
    background: rgba(0, 0, 0, 0.2);
  }

  &--active,
  &--active + & {
    &::before {
      display: none;
    }
  }

  &--active {
    background-color: white;
    box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.1);

    &::after {
      display: none;
    }
  }

  &--clickable {
    cursor: pointer;
  }

  &__direction {
    flex: 0 0 20px;
    position: relative;
    align-self: center;
  }

  &__direction-icon {
    position: absolute;
    left: -4px;
    top: 50%;
    transform: translateY(-50%);
    height: 20px;
    width: 20px;
    color: rgba(0, 0, 0, 0.7);

    &--northbound {
      color: var(--danger-color);
    }
  }

  &__route {
    flex: 1 1 0;
  }

  &__parking {
    flex: 1 1 0;
    color: rgba(0, 0, 0, 0.5);
    position: relative;
    padding-left: 22px;
  }

  &__parking-icon {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 14px;
    width: 14px;
    color: rgba(0, 0, 0, 0.7);
  }

  &__distance {
    flex: 0 0 110px;
    color: rgba(0, 0, 0, 0.5);
  }

  &__daily-distance {
    &:not(:first-child)::before {
      content: '';
      width: 1px;
      height: 10px;
      display: inline-block;
      background-color: rgba(0, 0, 0, 0.1);
      transform: rotate(20deg);
      margin: 0 8px;
    }
  }

  &__day-count {
    flex: 0 0 30px;
    text-align: right;
  }
}
