.About {
  &__inner {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  &__text {
    width: 380px;
    margin-left: 60px;
    line-height: 24px;
  }

  &__image {
    width: 300px;
  }

  &__link {
    position: relative;
    color: inherit;
    text-decoration: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    transition: border-color 0.2s;

    &:hover {
      border-bottom-color: rgba(0, 0, 0, 0.5);
    }
  }

  &__back-button {
    position: fixed;
    top: 20px;
    left: 20px;
    color: rgba(0, 0, 0, 0.5);
    line-height: 24px;
    cursor: pointer;
    background: none;
    border: none;
    transition: color 0.2s;

    // TODO add focus state
    &:focus {
      outline: none;
    }

    &:hover {
      color: rgba(0, 0, 0, 0.9);
      &::before {
        opacity: 0.7;
      }
    }

    &::before {
      content: '';
      background-image: url('~assets/arrow-left.svg');
      background-size: 100%;
      display: inline-block;
      width: 24px;
      height: 24px;
      opacity: 0.5;
      margin-right: 8px;
      vertical-align: middle;
      position: relative;
      top: -2px;
      transition: opacity 0.2s;
    }
  }
}
