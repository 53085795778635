.Calendar\/Day {
  --hover-color: #8cc9f5;
  --selected-color: #0a5890;

  width: var(--day-width);
  height: var(--day-width);
  margin: 0 var(--gutter-width) var(--gutter-width) 0;
  border-radius: 2px;
  border: 0;
  background-color: #dec9bb;

  cursor: pointer;
  position: relative;

  &--hidden {
    visibility: hidden;
  }

  &--disabled {
    background-color: #eee2d9;
    cursor: default;

    .Calendar\/Day__inner {
      color: rgba(0, 0, 0, 0.3);
    }
  }

  &:not(&--disabled):not(&--selected):hover {
    background-color: var(--hover-color);
  }

  &--selected {
    background-color: var(--selected-color);

    .Calendar\/Day__inner {
      color: white;
    }

    &.Calendar\/Day--disabled {
      background-color: #b8d1f6;

      .Calendar\/Day__inner {
        color: rgba(0, 0, 0, 0.3);
      }
    }
  }

  &__inner {
    position: absolute;
    top: calc(var(--gutter-width) / -2);
    right: calc(var(--gutter-width) / -2);
    bottom: calc(var(--gutter-width) / -2);
    left: calc(var(--gutter-width) / -2);
    font-size: 14px;
    color: rgba(0, 0, 0, 0.7);
    line-height: calc(var(--day-width) + var(--gutter-width));
    text-align: center;
  }

  &__annotation {
    position: absolute;
    top: -3px;
    right: -3px;
    overflow: visible;
    z-index: 1;
    color: #2363be;
    font-weight: 500;
    background: white;
    height: 12px;
    width: 12px;
    border-radius: 100%;
    text-align: center;
    line-height: 17px;
  }
}
