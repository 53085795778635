.Calendar\/Month {
  --day-width: 32px;
  --gutter-width: 4px;

  width: calc(7 * (var(--day-width) + var(--gutter-width)));
  user-select: none;
  margin: 0 calc(-1 * var(--gutter-width)) calc(-1 * var(--gutter-width)) 0;

  &--disabled {
    .Calendar\/Month__month,
    .Calendar\/Month__weekdays {
      color: rgba(0, 0, 0, 0.5);
    }
  }

  &__month {
    text-align: center;
    margin-bottom: 4px;
  }

  &__weekdays {
    display: flex;
    flex-wrap: wrap;
  }

  &__days {
    display: flex;
    flex-wrap: wrap;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__annotation {
    color: #2363be;
    overflow: visible;
    font-size: 14px;
    line-height: 18px;
    padding-right: var(--gutter-width);
    padding-left: 12px;
    position: relative;

    &::before {
      content: '*';
      position: absolute;
      left: 0;
      top: 2px;
      font-weight: 500;
      font-size: 16px;
    }

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}
