.Timeline\/Item {
  padding-left: 18px;
  line-height: 20px;
  position: relative;
  overflow: hidden;

  &:not(:last-child) {
    padding-bottom: 16px;
  }

  // line
  &::before {
    content: '';
    width: 2px;
    height: 100%;
    background-color: #e7e7e7;
    position: absolute;
    left: 2px;
    top: 10px;
  }

  &__name {
    position: relative;

    // dot
    &::before {
      content: '';
      width: 12px;
      height: 12px;
      background-color: #9c9189;
      position: absolute;
      left: -21px;
      top: 3px;
      border-radius: 100%;
      border: 3px solid white;
    }
  }

  &--shuttle::before,
  &--shuttle &__name::before,
  &--shuttle &__item::before {
    background-color: #1066e1;
  }

  &--hiking::before,
  &--hiking &__name::before,
  &--hiking &__item::before {
    background-color: #e9ac8f;
  }

  &__description {
    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
    margin-top: 4px;
  }

  &__item {
    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
    margin-top: 8px;
    position: relative;

    &::before,
    &::after {
      content: '';
      width: 10px;
      height: 10px;
      position: absolute;
      left: -20px;
      top: 3px;
      border-radius: 100%;
      border: 3px solid white;
    }

    // dot
    &::before {
      background-color: #9c9189;
      opacity: 0.5;
      z-index: 1;
    }

    // back of dot
    &::after {
      background-color: white;
    }
  }
}
